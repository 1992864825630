import { LazyLoadImageModule } from 'ng-lazyload-image'
import { AdminSharedModule } from './../admin-shared/admin-shared.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedModule } from 'src/app/website/shared/shared.module'
import { DataService } from './data.service'
import { RouterModule } from '@angular/router'
import { NgModule } from '@angular/core'
import { DashboardComponent } from './dashboard.component'

@NgModule({
    imports: [
        SharedModule,
        AdminSharedModule,
        ReactiveFormsModule,
        FormsModule,
        LazyLoadImageModule,
        RouterModule.forChild([
            {
                path: '',
                component: DashboardComponent
            }
        ])
    ],
    declarations: [DashboardComponent],
    providers: [DataService]
})
export class DashboardModule { }
