<!-- <h1>Dashboard</h1> -->
<div class="dashboard-main-container">
    <section class="dashboard-section">
        <div class="dashboard-container">
            <div class="dash-text">
                <p class="text-name">
                    Hi <span class="dash-name">{{ api.user.firstName }}!</span>
                </p>
                <!-- <p class="text-date">
                    Last Login:
                    <ng-container *ngIf="api.user.lastLogin === null; else lastLoginTemp">
                        <span class="dash-date" style="letter-spacing: 1px">First Time Login</span>
                    </ng-container>
                    <ng-template #lastLoginTemp>
                        <span class="dash-date">{{ api.user.lastLogin | date: 'MMMM d, y AT hh:mm a' }}</span>
                    </ng-template>
                </p> -->
            </div>
            <div class="dash-img-wrapper"><img src="/assets/images/logo.png" alt="" /></div>
        </div>
    </section>

    <h1 class="text-insight">INSIGHTS</h1>

    &nbsp;
    <div class="row">
        <div class="col-md-4 col-sm-4 d-flex">
            <div class="col-md-0.3 side-bar"></div>
            <div class="digits">
                <ng-container *ngIf="dataStatus === 'fetching'">
                    <div class="image-wrapper"><img src="/assets/images/rolling-main.svg" alt="" /></div>
                </ng-container>
                <span *ngIf="storedData.totalPayableAmount < 0">-${{ storedData.totalPayableAmount * -1 | number: '1.2-2' }}</span>
                <span *ngIf="storedData.totalPayableAmount >= 0">${{ storedData.totalPayableAmount | number: '1.2-2' }}</span>
                <p class="user-data-titles">Total Payable Amount</p>
            </div>
        </div>
        <!-- <div class="col-md-4 col-sm-4 d-flex">
            <div class="col-md-0.3 side-bar"></div>
            <div class="digits">
                <span *ngIf="storedData.data.totalAmountOwedMinusRegFee < 0">-${{ storedData.data.totalAmountOwedMinusRegFee * -1 | number: '1.2-2' }}</span>
                <span *ngIf="storedData.data.totalAmountOwedMinusRegFee >= 0">${{ storedData.data.totalAmountOwedMinusRegFee | number: '1.2-2' }}</span>
                <p class="user-data-titles">Total Amount Owed Minus Reg fee</p>
            </div>
        </div> -->
    </div>
    <!--<div class="row">
        <div class="col-md-4 col-sm-4 d-flex insight-container pt-2 pb-2" (click)="openStudents()">
            <div class="col-md-0.3 side-bar"></div>
            <div class="digits">
                <ng-container *ngIf="dataStatus === 'fetching'">
                    <div class="image-wrapper"><img src="/assets/images/rolling-main.svg" alt="" /></div>
                </ng-container>
                <span *ngIf="dataStatus === 'done'">{{ storedData.totalStudents ? storedData.totalStudents : 0 }}</span>
                <p class="user-data-titles">Total Students</p>
            </div>
        </div>

        <div class="col-md-4 col-sm-4 d-flex insight-container pt-2 pb-2" (click)="openTeams()">
            <div class="col-md-0.3 side-bar"></div>
            <div class="digits">
                <ng-container *ngIf="dataStatus === 'fetching'">
                    <div class="image-wrapper"><img src="/assets/images/rolling-main.svg" alt="" /></div>
                </ng-container>
                <span *ngIf="dataStatus === 'done'">{{ storedData.totalTeams ? storedData.totalTeams : 0 }}</span>
                <p class="user-data-titles">Total Teams</p>
            </div>
        </div>
        <div class="col-md-4 col-sm-4 d-flex insight-container pt-2 pb-2" (click)="openTransactions()">
            <div class="col-md-0.3 side-bar"></div>
            <div class="digits">
                <ng-container *ngIf="dataStatus === 'fetching'">
                    <div class="image-wrapper"><img src="/assets/images/rolling-main.svg" alt="" /></div>
                </ng-container>
                <ng-container *ngIf="status === true; else totalAmountOwed">
                    <span *ngIf="storedData.totalAmountOwed > 0">-${{ storedData.totalAmountOwed | number: '1.2-2' }}</span>
                    <span *ngIf="storedData.totalAmountOwed <= 0">${{ storedData.totalAmountOwed * -1 | number: '1.2-2' }}</span>
                </ng-container>
                <ng-template #totalAmountOwed><span *ngIf="dataStatus === 'done'">$0.00</span></ng-template>
                <p class="user-data-titles">Total Amount Owed</p>
            </div>
        </div>
    </div>-->
    &nbsp;
</div>
